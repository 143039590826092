import React from "react";
import { IconContext } from "react-icons";
import QonduitContext from "./QonduitContext";
import { Provider } from "../utils/QonduitClient";
import axios from "axios";
import Cookies from "js-cookie";

export default function Container(props: {
  children: React.ReactNode;
  onError?: (error: Error) => void;
}) {
  React.useEffect(() => {
    axios.interceptors.request.use(function onFulfilled(req) {
      const secret = Cookies.get("qonduit-admin-secret");
      if (secret) {
        req.headers["x-qonduit-admin-secret"] = secret;
      }
      const token = Cookies.get("qonduit-access-token");
      if (token) {
        req.headers["authorization"] = `Bearer ${token}`;
      }
      return req;
    });
    axios.interceptors.response.use(
      function onFulfilled(resp) {
        return resp;
      },
      function onRejected(error) {
        props.onError?.(error);
      }
    );
  }, []);
  return (
    <QonduitContext.Provider>
      <Provider>
        <IconContext.Provider value={{ color: "currentColor" }}>
          {props.children}
        </IconContext.Provider>
      </Provider>
    </QonduitContext.Provider>
  );
}
