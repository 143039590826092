import { ChakraProvider } from "@chakra-ui/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import "./global.css";
import React from "react";
import { useRouter } from "next/dist/client/router";
import Container from "../components/Container";
import theme from "../utils/theme";
import Cookies from "js-cookie";

function Qonduit({ Component, pageProps }: AppProps) {
  const router = useRouter();
  React.useEffect(() => {
    const secret = router.query["ADMIN-SECRET"];
    if (typeof secret === "string") {
      Cookies.set("qonduit-admin-secret", secret, {
        expires: 7,
      });
    }
  }, [router.query]);
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Container onError={(error) => {}}>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="true"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap"
            rel="stylesheet"
          />
        </Head>
        <Component {...pageProps} />
      </Container>
    </ChakraProvider>
  );
}

export default Qonduit;
